<div *ngIf="display">

    <div *ngIf="url!='/home'" class="header">
        <mat-toolbar style="position: fixed;"  
        fxHide  fxShow.gt-md class="header-v2 app-toolbar primary" fxLayoutAlign="space-between center">
          <mat-toolbar-row style="height: 100%;"  fxLayoutAlign="space-between center" class="theme-container">
        
               
        <div fxLayout="row" fxLayoutGap="10px" fxFlex="80">
            <calsoft-logo [sitename]="data.siteName" [type]="'light'"
            ></calsoft-logo>
            <calsoft-Menu [color]="true" fxLayoutAlign="center center" style="z-index: 1000;" [data]="data" [type]="'type'"
                [categoryArray]="item"></calsoft-Menu>
                
    
         
        </div>
    
        
    
        <div fxLayout="row" fxFlex fxLayoutGap="10px" fxLayoutAlign="end center">

            <div *ngIf="data.disableSearchInHeader==0">

            <div *ngIf="url != '/cart'  && url !== '/checkout' && url !=='/checkout/type'">
                <mat-icon style=" cursor: pointer; color:white"  fxLayoutAlign="center center" *ngIf="!displaySearchBar"
                    (click)="showSearchBar()">search</mat-icon>
            </div>
    
    
            <div *ngIf="displaySearchBar" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row"
                fxLayoutAlign="start center" fxFlex fxFlex.gt-sm="300px">
                <calsoft-search (callhideSearchBar)="hideSearch($event)" showSearchBar
                    [searchbarborderRadius]="'15px'"></calsoft-search>
            </div>
    
            </div>
            
        </div>
        <div fxFlex="2">
    
        </div>
    
        <div fxLayoutAlign="end center" *ngIf="url != '/cart'">
    
            <button style="color: white;" (click)="wishlist()" mat-icon-button matBadgePosition="after" matBadgeColor="accent">
                <i class="material-icons notranslate">
                    favorite_border
                </i>
            </button>
    
            <calsoft-cart-icon [color]="false"></calsoft-cart-icon>
    
        </div>
        
        <!-- <button *ngIf="!userLoggedIn" (click)="SignIn('login')" style="line-height: 15px" mat-button>
            <div style="color: #ffffff" fxLayout="column">
                <small>Hello, Sign in</small>
                <span>Account & Lists</span>
            </div>
        </button>
    
        <calsoft-HeaderUserProfileDropdown [data]="data" [type]="scroll" class="headerUser" *ngIf="userLoggedIn">
        </calsoft-HeaderUserProfileDropdown> -->
    
      
    
                    </mat-toolbar-row>
        
        </mat-toolbar>
        <mat-toolbar fxHide fxShow.gt-md >
        
        
        
        <mat-toolbar-row  fxLayout="row" fxLayoutAlign="center center"
              style=" background: #fff;margin-top: 90px;
              box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);padding: 0px;color: #000000;height: -webkit-fill-available"
              class="header-v2-bottom main-menu-wrap theme-container">
        
              
        
        </mat-toolbar-row >
          </mat-toolbar>
        </div>

    <div *ngIf="url=='/home'" class="header">
        <div class="header-wrapper" fxLayout="row" >
            <div fxLayout="row" fxFlex fxLayoutAlign="start center" >

                <section fxLayoutAlign="center center">
                    <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
                </section>

                <mat-toolbar-row fxHide fxShow.gt-sm class="container" fxLayout="row" fxLayout.xs="row" >
                    <calsoft-Menu [color]="true" [data]="data" [type]="'one'" style="color: #000000;" class="menu"
                        [categoryArray]="item"></calsoft-Menu>
                </mat-toolbar-row>

            </div>
            <div fxLayout="row" fxFlex="45"  fxLayoutAlign="end center">

                <div fxLayout="row" fxFlex fxLayoutGap="10px" fxLayoutAlign="end center">
                    <div *ngIf="url != '/cart'  && url !== '/checkout' && url !=='/checkout/type'">
                        <mat-icon style=" cursor: pointer; color: white;" fxLayoutAlign="center center" *ngIf="!displaySearchBar"
                            (click)="showSearchBar()">search</mat-icon>
                    </div>
    
    
                    <div *ngIf="displaySearchBar" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row"
                        fxLayoutAlign="start center" fxFlex fxFlex.gt-sm="300px">
                        <calsoft-search (callhideSearchBar)="hideSearch($event)" showSearchBar
                            [searchbarborderRadius]="'15px'"></calsoft-search>
                    </div>
    
                </div>

                <div fxFlex="20px">

                </div>

                <div *ngIf="url!='/account/wishlist'">
                    <button style="color: white;" (click)="wishlist()" mat-icon-button matBadgePosition="after" matBadgeColor="accent">
                        <i class="material-icons notranslate">
                            favorite_border
                        </i>
                    </button>
                </div>
                
                <calsoft-cart-icon [color]="false" ></calsoft-cart-icon>

                <div *ngIf="data.disableLoginHeader==0">
                <div *ngIf="!userLoggedIn" class="_sadh" (click)="SignIn('login')" fxLayoutAlign="start center">
                    <button mat-button class="w-color" *ngIf="!userLoggedIn"> <mat-icon style="color: white;">person</mat-icon>
                    </button>
                </div>
                </div>
        
                <calsoft-HeaderUserProfileDropdown [data]="data" class="headerUser" *ngIf="userLoggedIn">
                </calsoft-HeaderUserProfileDropdown>

            </div>

        </div>
    </div>

    <mat-toolbar fxHide fxShow.gt-md class="calsoft-fixed-header primary" fxLayout="row"
    fxLayoutAlign="space-around center" style="height: auto; z-index: 1000;">

    <mat-toolbar-row class="theme-container" fxLayoutAlign="space-between center" style="height: auto;" >

    <div fxLayout="row" fxLayoutGap="10px" fxFlex="80">


        <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

        <calsoft-Menu [color]="true" fxLayoutAlign="center center" style="z-index: 1000;" [data]="data" [type]="'type'"
            [categoryArray]="item"></calsoft-Menu>
            
    </div>

    

    <div fxLayout="row" fxFlex fxLayoutGap="10px" fxLayoutAlign="end center">
        <div *ngIf="url != '/cart'  && url !== '/checkout' && url !=='/checkout/type'">
            <mat-icon style=" cursor: pointer; color:white"  fxLayoutAlign="center center" *ngIf="!displaySearchBar"
                (click)="showSearchBar()">search</mat-icon>
        </div>


        <div *ngIf="displaySearchBar" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row"
            fxLayoutAlign="start center" fxFlex fxFlex.gt-sm="300px">
            <calsoft-search (callhideSearchBar)="hideSearch($event)" showSearchBar
                [searchbarborderRadius]="'15px'"></calsoft-search>
        </div>

    </div>
    <div fxFlex="2">

    </div>

    <div fxLayoutAlign="end center" *ngIf="url != '/cart'">

        <div *ngIf="data.disableWishlistHeader==0">
        <button style="color: white;" (click)="wishlist()" mat-icon-button matBadgePosition="after" matBadgeColor="accent">
            <i class="material-icons notranslate">
                favorite_border
            </i>
        </button>
        </div>

        <calsoft-cart-icon [color]="false"></calsoft-cart-icon>

    </div>
    

    <div fxFlex="3">

    </div>

    </mat-toolbar-row>
</mat-toolbar>

</div>

<div *ngIf="!display">
    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>
</div>